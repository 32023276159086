import { QueryClient, dehydrate } from '@tanstack/react-query'
import { SSRPropsContext } from 'next-firebase-auth'

import { fetchMyProfile, patchProfile } from '~/endpoints/user'
import { MY_PROFILE_QUERY_KEY } from '~/utils/constants'
import { getSetupClientSsr } from '~/utils/onsched'

export function toResourceKey(id?: string | null) {
  return ['onsched', 'setup', 'resource', id ?? '<invalid>'].filter(Boolean) as string[]
}

export function toServiceKey(id?: string | null) {
  return ['onsched', 'setup', 'service', id].filter(Boolean) as string[]
}

export function toAvailabilityKey(service?: string | null) {
  return ['onsched', 'consumer', 'availability', service].filter(Boolean) as string[]
}

export async function setupSellerInOnsched(context: SSRPropsContext) {
  const authUser = context.user
  const queryClient = new QueryClient()

  console.debug('Checking if current user has a seller id')
  try {
    let summary = await queryClient.fetchQuery({
      queryKey: [MY_PROFILE_QUERY_KEY],
      queryFn: () => fetchMyProfile(authUser?.getIdToken),
    })

    // Check to see if the current user is a seller and has a resource ID.
    if (summary.stripeSellerId) {
      console.debug('User is a seller, checking onsched resource and location')
      const client = await getSetupClientSsr(context)
      if (!summary.resourceId) {
        console.debug('Creating onsched resource and location for user')
        const loc = await client
          .v1LocationsCreate({
            name: summary.username.substring(0, 50),
            email: summary.email,
            website: `https://thatch.co/@${summary.username}`,
            friendlyId: `loc_${summary.uid}`,
            timezoneName: 'America/Los_Angeles',
            businessHours: {
              mon: { is24Hours: true, isOpen: true },
              tue: { is24Hours: true, isOpen: true },
              wed: { is24Hours: true, isOpen: true },
              thu: { is24Hours: true, isOpen: true },
              fri: { is24Hours: true, isOpen: true },
              sat: { is24Hours: true, isOpen: true },
              sun: { is24Hours: true, isOpen: true },
            },
          })
          .then(it => it.data)

        const locationId = loc.id

        // No resource so they need to be created in onsched.
        console.debug('Creating resource in OnSched')
        const resp = await client.v1ResourcesCreate({
          locationId,
          name: summary.username.substring(0, 50),
          email: summary.email,
          options: {
            googleCalendarId: summary.email,
            calendarAvailability: 1,
          },
        })
        console.debug('onsched response: [status=%s, message=%s]', resp.status, resp.statusText)
        if (resp.status === 200) {
          const scheduling = resp.data
          const resourceId = scheduling.id
          console.debug(
            'Storing resource and location ID in backend: [resource=%s, location=%s]',
            resourceId,
            locationId
          )
          summary = await patchProfile({ resourceId, locationId }, authUser?.getIdToken)
          queryClient.setQueryData([MY_PROFILE_QUERY_KEY], summary)
          queryClient.setQueryData(['onsched', 'setup', 'resource', resourceId], scheduling)
        } else {
          console.warn('unexpected response', resp)
        }
      } else {
        const locId = summary.locationId as string
        const loc = await client.v1LocationsDetail(locId)
        if (loc.data.friendlyId?.startsWith('loc_') !== true) {
          await client
            .v1LocationsUpdate(locId, {
              name: summary.username.substring(0, 50),
              email: summary.email,
              website: `https://thatch.co/@${summary.username}`,
              friendlyId: `loc_${summary.uid}`,
              businessHours: {
                mon: { is24Hours: true, isOpen: true },
                tue: { is24Hours: true, isOpen: true },
                wed: { is24Hours: true, isOpen: true },
                thu: { is24Hours: true, isOpen: true },
                fri: { is24Hours: true, isOpen: true },
                sat: { is24Hours: true, isOpen: true },
                sun: { is24Hours: true, isOpen: true },
              },
            })
            .then(it => it.data)
          try {
            await client.v1LocationsSettingsScopeUpdate(locId, '0')
          } catch (e) {
            console.warn('Ignoring error setting location scope: %s', e.message)
          }
          console.info('Onsched location updated')
        }

        console.info('Fetching resource from onsched')
        await queryClient.fetchQuery({
          queryKey: toResourceKey(summary.resourceId),
          queryFn: ({ queryKey }) =>
            client.v1ResourcesDetail(queryKey[3] as string).then(it => it.data),
        })
      }
    }

    return {
      props: {
        isError: false,
        dehydratedState: dehydrate(queryClient),
      },
    }
  } catch (e) {
    console.error('Failed to process seller resources', e)
    return { props: { isError: true } }
  }
}

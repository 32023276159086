import type { GetServerSidePropsContext } from 'next'
import { stringify } from 'qs'
import { Token } from 'simple-oauth2'

import { ConsumerClient } from '~/client/onsched/consumer'
import { SetupClient } from '~/client/onsched/setup'
import { isServerSide } from '~/utils/ssr'

declare module 'iron-session' {
  interface IronSessionData {
    token?: Token
  }
}

export function getSetupClient(host = '') {
  return new SetupClient({ baseURL: `${host}/api/schedule/` })
}

export async function getSetupClientSsr(context: GetServerSidePropsContext) {
  if (isServerSide()) {
    const { onschedApiHost, getAccessToken } = await import('./onsched-ssr')
    return new SetupClient({
      baseURL: onschedApiHost,
      securityWorker: async () => {
        const token = await getAccessToken(context.req, context.res)
        return {
          headers: {
            authorization: `Bearer ${token.access_token}`,
          },
        }
      },
    })
  }

  throw new Error('Server side only code')
}

export function getConsumerClient(host = '') {
  return new ConsumerClient({
    baseURL: `${host}/api/schedule/`,
    paramsSerializer: params => stringify(params, { arrayFormat: 'repeat', encode: false }),
  })
}

export async function getConsumerClientSsr(context: GetServerSidePropsContext) {
  if (isServerSide()) {
    const { onschedApiHost, getAccessToken } = await import('./onsched-ssr')
    return new ConsumerClient({
      baseURL: onschedApiHost,
      securityWorker: async () => {
        const token = await getAccessToken(context.req, context.res)
        return {
          headers: {
            authorization: `Bearer ${token.access_token}`,
          },
        }
      },
    })
  }

  throw new Error('Server side only code')
}

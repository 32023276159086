import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { formatISO } from 'date-fns'
import addDays from 'date-fns/addDays'

import { ResourceUpdateModel } from '~/client/onsched/setup/data-contracts'
import { getConsumerClient, getSetupClient } from '~/utils/onsched'
import { toAvailabilityKey, toResourceKey } from '~/utils/onsched-common'

export function useOnschedResource(id?: string | null) {
  const client = getSetupClient()
  const key = toResourceKey(id)

  return useQuery({
    queryKey: key,
    queryFn: ({ queryKey }) =>
      client.v1ResourcesDetail(queryKey[3] as string).then(it => {
        return it.data
      }),
    refetchOnWindowFocus: false,
    enabled: !!id,
  })
}

export function useOnschedAvailability(service: string, location: string, timezone?: string) {
  const client = getConsumerClient()
  const key = toAvailabilityKey(service)

  const tz = timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZoneName ?? ''

  return useQuery({
    queryKey: [...key, location, tz],
    queryFn: async ({ queryKey }) => {
      const [svc = '', location = '', tz = ''] = queryKey.slice(3)
      const start = formatISO(Date.now(), { representation: 'date' })
      const endDate = addDays(Date.now(), 10)
      const end = formatISO(endDate, { representation: 'date' })
      const it = await client.v1AvailabilityDetail(svc, start, end, {
        locationId: location,
        timezoneName: tz,
      })
      return it.data
    },

    refetchOnWindowFocus: false,
  })
}

export function useMutateOnschedResource(id?: string | null) {
  const queryClient = useQueryClient()
  const client = getSetupClient()
  const key = toResourceKey(id)

  return useMutation({
    mutationKey: key,
    mutationFn: async (updates: ResourceUpdateModel) => {
      if (!id) {
        console.warn('Ignoring mutation with missing resource ID')
        return null
      }

      const resp = await client.v1ResourcesUpdate(id, updates, {
        googleAuthReturnUrl: window.location.toString(),
      })
      return resp.data
    },

    onSuccess: data => {
      if (data) {
        queryClient.setQueryData(key, data)
      } else {
        console.warn('No data returned from mutation')
      }
    },
  })
}
